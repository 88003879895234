/* 万擎云、全景拍摄服务、智慧文旅、智慧教育、招商加盟、AR创意运营-首页
AR创意运营-案例、AR创意运营-解决方案、大客户定制运营-案例、AI学科、h5AR创意运营
万目云h5-VR全景网站、h5 大客户定制 职业培训 元宇宙解决方案 媒体运营*/
import textData from './textData'
// 万擎云
const WanqingCloud = {
  cardList: [
    {
      title: '图形化界面',
      src: "https://h5.ophyer.cn/official_website/other/WanqingCloud-1.png",
      content: '万擎云提供图形化的编辑页面，让3D场景搭建、炫酷动画的制作变得简单'
    },
    {
      title: '低代码脚本',
      src: "https://h5.ophyer.cn/official_website/other/WanqingCloud-2.png",
      content: '简单易用的预制接口，让用户通过少量代码即可写出优雅的脚本'
    },
    {
      title: '跨平台 多终端',
      src: "https://h5.ophyer.cn/official_website/other/WanqingCloud-3.png",
      content: '支持安卓、IOS、H5小程序等多渠道同时发布，确保触达更多用户场景'
    }
  ],
  productList: [
    { title: 'XX小区样板间', src: "https://cdn.vuetifyjs.com/images/cards/house.jpg", content: 'XX小区新房销售' },
    { title: '汽车展厅', src: "https://cdn.vuetifyjs.com/images/cards/road.jpg", content: '汽车线上展览会' },
    { title: '全名跳一跳', src: "https://cdn.vuetifyjs.com/images/cards/road.jpg", content: 'XX游戏' },
    { title: '3D弹球', src: "https://cdn.vuetifyjs.com/images/cards/road.jpg", content: 'XX游戏' }
  ],
  items: [
    {
      tab: '3D场景搭建',
      title: '快速搭建3D场景',
      content: ['平台预设的3D模型，搭配用户上传的贴图可以快速搭建业务场景\n所需要的3D场景'],
      src: "https://h5.ophyer.cn/official_website/other/develop5-1new.png"
    },
    {
      tab: '动画制作',
      title: '序列帧、粒子动画快速生成',
      content: ['序列帧动画编辑工具可以快速制作序列帧动画', '内置粒子工具轻松制作粒子动画特效，让动画效果更上一层楼'],
      src: "https://h5.ophyer.cn/official_website/other/WanqingClou-5-bg.png"
    },
    {
      tab: '脚本自定义',
      title: '预设API，场景表现更随心',
      content: ['通过预设API控制场景元素运动及动画的播放，让场景的表现更随心'],
      src: 'https://h5.ophyer.cn/official_website/other/WanqingClou-6-new.png'
    },
  ]
}
// 全景拍摄服务
const ShootingService = {
  items: [
    {
      tab: "VR拍摄",
      title: "金牌摄影师专业拍摄",
      content: "国内知名摄影师提供一对一上门服务，高效率、专业级拍摄，完美还原真实场景",
      src: 'https://h5.ophyer.cn/official_website/other/ShootingService-1.png'
    },
    {
      tab: "设备租赁",
      title: "3C大片级专业设备",
      content:
        "国产、进口专业设备租赁，提供VR全景拍摄、视频拍摄、照片拍摄、3D环物拍摄",
      src: 'https://h5.ophyer.cn/official_website/other/ShootingService-2.png'
    },
    {
      tab: "全景制作",
      title: "专业技术团队打造真实全景",
      content:
        "行业内10年深耕技术团队一对一定制化开发，致力于打造一比一真实场景",
      src: 'https://h5.ophyer.cn/official_website/other/ShootingService-3.png'
    },
    {
      // tab: "运营内容策划",
      tab: "活动内容策划",
      // title: "助力企业扩展运营方式",
      title: "助力企业扩展品牌运营",
      // content:"十二年品牌运营经验资深运营专家为企业提供定制化VR全景运营策略，助力企业运营方式拓展",
      content: "十二年品牌运营经验资深运营专家为企业提供定制化VR全景运营策略，助力企业品牌运营的拓展",
      // src:'https://h5.ophyer.cn/official_website/other/ShootingService-4.png'
      src: 'https://h5.ophyer.cn/official_website/other/ShootingService-4_1.png'
    },
  ],
  cardList: [
    {
      title: "专业团队",
      content:
        "深耕行业10年，更了解您的需求，5年以上顾问经验提供7*24小时专属服务，为您解决所有拍摄问题",
      src: require('../../public/img/icons/ShootingService/ShootingService-1.png'),
    },
    {
      title: "高端设备",
      content:
        "涵盖所有型号国内设备及进口设备，为您提供全方位的拍摄服务与制作服务",
      src: require('../../public/img/icons/ShootingService/ShootingService-2.png'),
    },
    {
      title: "丰富的运营经验",
      // content:"飞天云动对线上、线下市场有着深度的了解和丰富的运营经验，并与头部渠道有着深度战略合作关系，协助品牌快速精准落地运营策略",
      content: "飞天云动对线上、线下市场有着深度的了解和丰富的运营经验，并与头部渠道有着深度战略合作关系，协助品牌快速精准落地运营策略",
      src: require('../../public/img/icons/ShootingService/ShootingService-3.png'),
    },
    {
      title: "企业信赖",
      content: "深耕行业10年，众多合作伙伴，他们得选择源于对我们的信赖",
      src: require('../../public/img/icons/ShootingService/ShootingService-4.png'),
    },
  ],
  peitaoList: [
    // { title: '导播间', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-1.png'},
    // { title: '休息区', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-2.png'},
    // { title: '会议室', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-3.png'},
    // { title: '化妆间', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-4.png'},
    // { title: '摄像', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-5.png'},
    // { title: '设计策划', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-6.png'},
    // { title: '演员灯光', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-7.png'},
    // { title: '影视后期', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-8.png'},
    { title: '博物馆', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-9.png' },
    { title: '艺术展图', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-10.png' },
    { title: '商业会展', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-11.png' },
    { title: '学校/企业', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-12.png' },
    { title: '建筑酒店', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-13.png' },
    { title: '旅游景区', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-14.png' },
  ],
  cooperation: require('../../public/img/icons/ShootingService/cooperation.png'),
}
// 智慧文旅
const IntelligentCulturalTourism = {
  cards: [
    {
      title: "智慧景区",
      src: "https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png",
      content: "通过AR/VR技术帮助城市实现智慧化产业升级转型。“一部手机游景区”，宣告飞天云动具备智慧旅游、数字服务及大数据处理能力",
    },
    {
      title: "智慧展馆",
      src: "https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-2.png",
      // content: "结合地方特色，为城市定制独具特色的科技文化展馆。实现文化与科技一体化沉浸展示，打造现象级运营案例",
      content: "结合地方特色，为城市定制独具特色的科技文化展馆。实现文化与科技一体化沉浸展示，打造现象级智慧展馆案例",
    },
    {
      title: "虚拟展厅",
      src: "https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-3.png",
      content: "借助文化IP、结合客户需求，让用户享受科技带来的沉浸式展览体验，打造爆款级科技虚拟展厅",
    },
    {
      title: "VR景区体验",
      src: "https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-4.png",
      content: "为景区打造专属VR看景功能。通过移动端设备即可浏览不同景点的3D景象。帮助游客多角度、近距离观赏景点景象。",
    },
  ]
}
// 智慧教育
const WisdomEducation = {
  sheetList: [
    {
      title: "强大的交互性",
      content:
        "让学生更好的参与知识情景中，每个知识点形成双向交互，让孩子学习知识的过程有参与感",
      src: require("../../public/img/icons/WisdomEducation/WisdomEducation-1.png"),
    },
    {
      title: "趣味教学提高孩子自主能动性",
      content:
        "将知识点获取变为趣味小游戏，利用孩子好奇心里让孩子自主学习并加深记忆",
      src: require("../../public/img/icons/WisdomEducation/WisdomEducation-2.png"),
    },
    {
      title: "真实情景教学",
      content:
        "摒弃传统的视频+图片形式，利用全景教学让孩子融入真实的场景中提高孩子记忆力",
      src: require("../../public/img/icons/WisdomEducation/WisdomEducation-3.png"),
    },
  ],
  itemList: [
    {
      title: '中小学科学、天文地理生物',
      content: '利用全景模拟天文地理景色、景象，免去图片、视频等传统方式来传授的知识，现在直接呈现在孩子们的眼前，让他们切身体会',
      src: 'https://h5.ophyer.cn/official_website/other/WisdomEducation-1.png'
    },
    {
      title: 'VR线上研学课',
      content: '包含名人故居，历史文物、建筑，纪念展馆等220个基地，将教研内容与全景结合，互动式体验，提升教研效果',
      src: 'https://h5.ophyer.cn/official_website/other/WisdomEducation-2.png'
    },
    {
      title: '爱国教育引起学生共鸣',
      content: 'VR实景下，带学生真实走过长征路，体验前辈先人的爬雪山、过草地、大渡河，带学生再次走进那段艰苦的岁月，体验抗日战争胜利的艰辛，唤起学生共鸣，激发学生爱国主义精神',
      src: 'https://h5.ophyer.cn/official_website/other/WisdomEducation-3.png'
    },
    {
      title: '虚拟仿真实训室建设',
      content: '针对不同的行业、专业提供数字化仿真训练室，涵盖汽修、化工、旅游、电力、护理等专业，降低培训成本，提高教学质量',
      src: 'https://h5.ophyer.cn/official_website/other/WisdomEducation-4.png'
    }

  ]
}
// 招商加盟
const InvestmentPromotion = {
  items: [
    {
      tab: "高额收益",
      src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-1.png'),
      content: '高额销售返利，新签客户、老客户续约、定制化服务',
    },
    {
      tab: "全面培训",
      src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-2.png'),
      content: '产品、市场、运营全面无死角培训，帮助合作伙伴最短时间内了解行业',
    },
    {
      tab: "强力扶持",
      src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-3.png'),
      content: '渠道经理驻地支持，帮助合作伙伴快速开拓市场',
    },
    {
      tab: "销售工具",
      src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-4.png'),
      content: '提供线上线下全面销售工具，帮助合作伙伴提高效率',
    },
    {
      tab: "推广物料",
      src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-5.png'),
      content: '提供合作伙伴市场推广时所用的运营物料',
    },
    {
      tab: "服务支撑",
      src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-6.png'),
      content: '专属客户服务，帮助合作伙伴解决产品、技术等问题',
    },
  ],
  advantage: [
    {
      tab: "市场前景广阔",
      src: 'https://h5.ophyer.cn/official_website/other/InvestmentPromotion-1.png',
      titleList: [
        {
          title: "政策扶持",
          content:
            "近年来国家不断推出政策、发展基金等助推产业发展；国家十四五规划将AR/VR技术正式写入数字经济重点产业领域",
        },
        {
          title: "5G+时代",
          content:
            "5G时代来临，信息网络更加快速、稳定成熟，为AR/VR的发展提供了良好的契机",
        },
        {
          title: "产业聚集",
          content:
            "随着VR技术的更迭，VR产业逐渐形成规模化、应用化，市场需求量骤增",
        },
      ],
      iconImg: require("@/assets/images/InvestmentPromotion/marketProspect.png")
    },
    {
      tab: "稳定的技术支持",
      src: 'https://h5.ophyer.cn/official_website/other/InvestmentPromotion-2.png',
      titleList: [
        {
          title: "技术基础坚实",
          content:
            "10年技术沉淀，深耕AR/VR、AI及运营领域，在国内的AR/VR智慧运营领域名列前茅",
        },
        {
          title: "全栈自研技术",
          content: "独立自主研发AR/VR引擎 ｜ 累计获得软件著作权234个，授权专利4个，作品著作权21个",
        },
        {
          title: "稳定的开发团队",
          content:
            "100+技术团队强有力支撑，团队成员稳定，为系统开发升级保驾护航",
        },
      ],
      iconImg: require("@/assets/images/InvestmentPromotion/technology.png")
    },
    {
      tab: "全面服务保障",
      src: 'https://h5.ophyer.cn/official_website/other/InvestmentPromotion-3.png',
      titleList: [
        {
          title: "多渠道在线客服",
          content: "7x24小时应急响应，只要您需要，我们随时在身边",
        },
        {
          title: "顾问式运营服务",
          content: "项目全链路追踪，全程指导，为运营活动持续助力",
        },
        {
          title: "智能数据分析",
          content:
            "运营活动效果监控，实时追踪活动数据，为运营方案调整提供数据支持",
        },
      ],
      iconImg: require("@/assets/images/InvestmentPromotion/service.png")
    },
  ],
}
//AR创意运营-首页
const ARHome = {
  'modular': {
    title: 'AR互动游戏',
    titleBg: 'AR INTERACTIVE GAME',
    subtitle: '海量游戏模板+多种运营玩法，助力商家运营转化'
  },
  advantage: [
    {
      tab: "海量模板一键套用",
      src: 'https://h5.ophyer.cn/official_website/other/ARhome1-4.png',
      titleList: {
        title: "分场景、分目标，\n满足多种需求的运营玩法",
        content:
          "拉新、引流、促活、促转化，轻松制作即可生成符合自己运营需求的趣味小游戏",
      },
      titleBtn: ['· 促进活跃', '· 获客拉新', '· 节日运营', '· 品牌宣传'],
      iconImg: require("@/assets/images/InvestmentPromotion/marketProspect.png")
    },
    {
      tab: "趣味AR识别",
      src: 'https://h5.ophyer.cn/official_website/other/ARhome1-2.png',
      titleList: {
        title: "多种玩法的AR识别方式\n全方位调动用户参与性",
        content:
          "图像识别、物体识别、人脸识别、陀螺仪、重力感应，更多趣味玩法持续迭代中…",
      },
      titleBtn: ['· 图像识别', '· 人脸识别', '· 陀螺仪', '· 重力感应'],
      iconImg: require("@/assets/images/InvestmentPromotion/technology.png")
    },
    {
      tab: "多种奖励玩法",
      src: 'https://h5.ophyer.cn/official_website/other/ARhome1-3-1.png',
      titleList: {
        title: "转盘、抽奖、分层奖励等多种奖励玩法，满足更多运营场景",
        content: "AR互动游戏结合实物、卡券/卡密、积分等多种奖励方式，游戏好玩又有趣，有效刺激用户自传播",
      },
      titleBtn: ['· 转盘抽奖', '· 红包奖励', '· 游戏得分', '· 游戏时长'],
      iconImg: require("@/assets/images/InvestmentPromotion/service.png")
    },
    {
      tab: "权益分层派发",
      src: 'https://h5.ophyer.cn/official_website/other/ARhome1-1-1.png',
      titleList: {
        title: "权益分层派发，引导玩家持续互动\n提升游戏曝光率",
        content: "按游戏时长，得分，排名三种方式分层派发奖励，玩的越好越有可能赢取更好的奖励，持续刺激玩家积极参与",
      },
      titleBtn: ['· 游戏得分', '· 游戏时长', '· 游戏排名'],
      iconImg: require("@/assets/images/InvestmentPromotion/service.png")
    },
  ],
  itemList: [
    {
      title: '模板编辑方式\n轻松生成趣味互动内容',
      content: '简单操作即可完成内容制作，降低使用门槛',
      src: 'https://h5.ophyer.cn/official_website/other/ARhome2-1-1.png'
    },
    {
      title: '游戏界面灵活配置\n提升品牌影响力',
      content: '界面元素及内容均支持自定义，让用户在参与游戏的同时加深品牌印象',
      src: 'https://h5.ophyer.cn/official_website/other/ARhome2-2.png'
    }

  ],
  'case': {
    title: '案例精选',
    titleBg: 'CASE SELECTION',
    subtitle: '强互动 重效果 助力商家提升运营效果',
    anli: [textData.anli.momojiang, textData.anli.weida, textData.anli.mingshengyaoye, textData.anli.delongkafeiji]
  },
  'ARadvantage': {
    title: '我们的优势',
    titleBg: 'ADVANTAGE',
    subtitle: '强互动 重效果 助力商家提升运营效果',
    ProductCard: [
      { img: 'https://h5.ophyer.cn/official_website/other/IPhy31.png', icon: require('../../public/img/icons/AR/ARhome4-1.png'), title: '趣味互动', content: 'AR趣味互动，打造运营玩法新体验；结合多种权益奖励，助力运营指标' },
      { img: 'https://h5.ophyer.cn/official_website/other/IPhy32.png', icon: require('../../public/img/icons/AR/ARhome4-2.png'), title: '海量模板', content: '丰富的运营模板，简单操作即可完成自主创意的AR小游戏，降低使用门槛' },
      { img: 'https://h5.ophyer.cn/official_website/other/IPhy33.png', icon: require('../../public/img/icons/AR/ARhome4-3.png'), title: '组件丰富', content: '抽奖，转盘、娃娃机、红包等热门运营插件，提升活跃与销售转化' },
      { img: 'https://h5.ophyer.cn/official_website/other/IPhy34.png', icon: require('../../public/img/icons/AR/ARhome4-4.png'), title: '技术保障', content: '100+资深工程师7*24小时在线，全天候服务，保障活动稳定' }
    ],
  },
}
// AR创意运营-案例
const ARcase = [
  {
    title: '精选案例',
    tabItem: [
      textData.anli.haoshixian,
      textData.anli.haerbingpijiu,
      textData.anli.delongkafeiji,
      textData.anli.canlangang,
      textData.anli.ardiqiuyi,
      textData.anli.aojiabaohuyan,
      textData.anli.dongchengjieneng,
      textData.anli.shuijingfang,
      textData.anli.zhongxuediqiuyi,
      textData.anli.feitianyundong,
      textData.anli.wangshangchufang,
      textData.anli.yingteermanghe,
      textData.anli.leiyoushanghai,
      textData.anli.wanjuchezhan,
      textData.anli.kunyuanyimeng,
      textData.anli.yuanliangxiaochuan,
      textData.anli.mengniu,
      textData.anli.haixingkongtiao,
      textData.anli.feixingsheji,
      textData.anli.sitongbanjia,
      textData.anli.gedou,
      textData.anli.dingshengdichang,
      textData.anli.qiandudu,
      textData.anli.costa,
      textData.anli.zhongxing,
      textData.anli.baohulu,
      textData.anli.qinghuaxiaoyuan,
      textData.anli.taiqiu,
      textData.anli.jiamianfandian,
      textData.anli.dahuaxiyoutg,
      textData.anli.dahuaxiyouwutai,
      textData.anli.bowuguan,
      textData.anli.arbaikeditu,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.paizhaoshibie,
      textData.anli.jingqudaka,
      textData.anli.jiadian,
    ]
  },
  {
    title: '电商运营',
    tabItem: [
      textData.anli.delongkafeiji,
      textData.anli.feitianyundong,
      textData.anli.haerbingpijiu,
      textData.anli.haoshixian,
      textData.anli.yingteermanghe,
      textData.anli.aojiabaohuyan,
      textData.anli.leiyoushanghai,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.canlangang,
      textData.anli.shuijingfang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.haixingkongtiao,
      textData.anli.costa,
      textData.anli.zhongxing,
      textData.anli.baohulu,
      textData.anli.wanjuchezhan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.jiadian,
    ]
  },
  {
    title: '餐饮服务',
    tabItem: [
      textData.anli.haoshixian,
      textData.anli.aojiabaohuyan,
      textData.anli.haerbingpijiu,
      textData.anli.canlangang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.costa,
    ]
  },
  {
    title: '智慧教育',
    tabItem: [
      textData.anli.zhongxing,
      textData.anli.kunyuanyimeng,
      textData.anli.zhongxuediqiuyi,
      textData.anli.arbaikeditu
    ]
  },
  {
    title: '智慧文旅',
    tabItem: [
      textData.anli.leiyoushanghai,
      textData.anli.yuanliangxiaochuan,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.bowuguan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.paizhaoshibie,
      textData.anli.dahuaxiyoutg,
      textData.anli.jingqudaka
    ]
  },
  {
    title: '房产家居',
    tabItem: [
      textData.anli.haixingkongtiao,
      textData.anli.dingshengdichang,
      textData.anli.jiadian
    ]
  },
  {
    title: '公共服务',
    tabItem: [
      textData.anli.dongchengjieneng,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.sitongbanjia,
    ]
  },
  {
    title: '游戏',
    tabItem: [
      textData.anli.feitianyundong,
      textData.anli.gedou,
      textData.anli.mengniu,
      textData.anli.feixingsheji,
      textData.anli.taiqiu,
      textData.anli.dahuaxiyoutg,
    ]
  },
]
// AR创意运营-解决方案
const ARSolution = {
  modular: {
    // title:'结合运营目标，提供高效解决方案',
    title: '结合商家目标，提供高效解决方案',
    titleBg: '未完待续',
    subtitle: '基于多年技术沉淀 驱动交互创新，实现业务增长',
    items: [
      {
        tab: '品牌宣传',
        title: 'AR识别品牌LOGO加深品牌印象',
        content: '识别图、游戏元素、结算页面等均可添加品牌LOGO，提高品牌曝光度，加深印象',
        src: "https://h5.ophyer.cn/official_website/other/WanqingClou-4-bg.png"
      },
      {
        tab: '线下引流获客',
        title: '丰富玩法结合礼包、门店券、礼品卡为门店强势引流',
        content: '分层、抽奖、集卡等丰富的运营组件，让用户在游戏的过程中获得奖励，引导线下核销',
        src: "https://h5.ophyer.cn/official_website/other/WanqingClou-5-bg.png"
      },
      {
        tab: '线上产品促销',
        title: '优惠券、积分等权益奖励有效促进’上转化，提升GMV',
        content: '通过发放优惠券、积分以及直接跳转上线门店，有效拉客单，促进用户消费',
        src: 'https://h5.ophyer.cn/official_website/other/WanqingClou-6-new.png'
      },
      {
        tab: '公众号吸粉',
        title: '通过授权公众号，有效吸粉养粉构建私域流量',
        content: '用户可授权自主公众号，将公众号二维码展示在游戏界面，为公众号吸粉',
        src: "https://h5.ophyer.cn/official_website/other/WanqingClou-5-bg.png"
      },
      {
        tab: '裂变增长',
        title: '分享复活及朋友圈海报有效促进活动',
        content: '用户可授权自主公众号，将公众号二维码展示在游戏界面，为公众号吸粉',
        src: 'https://h5.ophyer.cn/official_website/other/WanqingClou-6-new.png'
      },
    ]
  },
  ARAbility: {
    title: '丰富的AR能力',
    titleBg: 'AR ABILITY',
    subtitle: '图像、物体、面部等多种识别方式 互动体验更强',
    items: [
      {
        title: '图形识别',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title: '物体识别',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title: '人脸识别',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title: '陀螺仪追踪',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title: '到店扫码',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title: '3D动画',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title: '透明Webview',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title: '贴片动画',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      }
    ]
  },
  entrance: {
    // title:'活动入口更灵活 为运营蓄能',
    title: '活动入口更灵活',
    titleBg: 'ACTIVITY ENTRANCE',
    subtitle: '微信、APP、web等多种打开方式 促进活动传播',
    items: [
      {
        title: '资源位/游戏专题',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title: '公众号菜单跳转',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title: '微信扫图识别',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title: '朋友圈海报分享',
        src: 'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
    ]
  }
}
//大客户定制运营-解决方案
const customersSolution = {
  itemList: [
    {
      title: '线下门店',
      content: '引流新客到店，提高老带新能力，提高原有客单价，促进成交',
      src: 'https://h5.ophyer.cn/official_website/other/cus01.png'
    },
    {
      title: '电商场景',
      content: '促进拉新，提高用户活跃，引导用户关注公司公众号，为私域流量构建持续蓄力',
      src: 'https://h5.ophyer.cn/official_website/other/cus02.png'
    },
    {
      title: '微信生态场景',
      content: 'VR实景下，带学生真实走过长征路，体验前辈先人的爬雪山、过草地、大渡河，带学生再次走进那段艰苦的岁月，体验抗日战争胜利的艰辛，唤起学生共鸣，激发学生爱国主义精神',
      src: 'https://h5.ophyer.cn/official_website/other/cus03.png'
    },
    {
      title: 'APP应用场景',
      content: 'API对接实现各类权益体系互通，助力用户长线运营',
      src: 'https://h5.ophyer.cn/official_website/other/cus04.png'
    },
    {
      title: '匹配品牌风格',
      content: '专业一对一定制，根据客户风格定制专属风格AR、VR项目，提供专业化行业解决方案',
      src: 'https://h5.ophyer.cn/official_website/other/cus05.png'
    }

  ]
}
// 大客户定制运营-案例
const customersCase = [
  {
    title: '精选案例',
    tabItem: [
      textData.anli.feitianyundong,
      textData.anli.haerbingpijiu,
      textData.anli.haoshixian,
      textData.anli.delongkafeiji,
      textData.anli.yingteermanghe,
      textData.anli.aojiabaohuyan,
      textData.anli.leiyoushanghai,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.canlangang,
      textData.anli.shuijingfang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.haixingkongtiao,
      textData.anli.costa,
      textData.anli.zhongxing,
      textData.anli.baohulu,
      textData.anli.wanjuchezhan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.jiadian,
      textData.anli.weida,
      textData.anli.mingshengyaoye,
      textData.anli.kidsyundian,
      textData.anli.nike_vr,
      textData.anli.dafeixing,
      textData.anli.sanzhisongshu_jd_tm,
      textData.anli.baicaiershouche,
      textData.anli.wanshifujiaju,
      textData.anli.quanjingjiushui,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.gaoerfu,
      textData.anli.haiyangguan,

    ]
  },
  {
    title: '电商运营',
    tabItem: [
      textData.anli.feitianyundong,
      textData.anli.haerbingpijiu,
      textData.anli.haoshixian,
      textData.anli.delongkafeiji,
      textData.anli.yingteermanghe,
      textData.anli.aojiabaohuyan,
      textData.anli.leiyoushanghai,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.canlangang,
      textData.anli.shuijingfang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.haixingkongtiao,
      textData.anli.costa,
      textData.anli.zhongxing,
      textData.anli.baohulu,
      textData.anli.wanjuchezhan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.jiadian,
      textData.anli.weida,
      textData.anli.mingshengyaoye,
      textData.anli.kidsyundian,
      textData.anli.nike_vr,
      textData.anli.dafeixing,
      textData.anli.sanzhisongshu_jd_tm,
      textData.anli.baicaiershouche,
      textData.anli.wanshifujiaju,
      textData.anli.quanjingjiushui,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.gaoerfu,
      textData.anli.haiyangguan,
      textData.anli.haoshixian,
      textData.anli.aojiabaohuyan,
      textData.anli.haerbingpijiu,
      textData.anli.canlangang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.costa,
      textData.anli.zhongxing,
      textData.anli.kunyuanyimeng,
      textData.anli.zhongxuediqiuyi,
      textData.anli.arbaikeditu,
      textData.anli.ardiqiuyi,
      textData.anli.wodenongchang,
      textData.anli.shengbimaliang,
      textData.anli.qicezhuangpei,
      textData.anli.xiaofangrenyuan,
      textData.anli.zhongxuedili,
      textData.anli.lajifenlei,
      textData.anli.meilizhongguo,
      textData.anli.hongcunjiyi,
      textData.anli.qiaojiadayuan,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.youlun,
      textData.anli.haiyangguan,
      textData.anli.leiyoushanghai,
      textData.anli.yuanliangxiaochuan,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.bowuguan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.paizhaoshibie,
      textData.anli.dahuaxiyoutg,
      textData.anli.jingqudaka,
      textData.anli.meilizhongguo,
      textData.anli.hongcunjiyi,
      textData.anli.qiaojiadayuan,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.youlun,
      textData.anli.haiyangguan,
      textData.anli.haixingkongtiao,
      textData.anli.dingshengdichang,
      textData.anli.jiadian,
      textData.anli.dongchengjieneng,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.sitongbanjia,
      textData.anli.wodenongchang,
      textData.anli.miaojiankang,
      textData.anli.xiaofangrenyuan,
      textData.anli.liantong,
      textData.anli.lajifenlei,
      // textData.anli.baicaiershouche,
      // textData.anli.gaoerfu,
      // textData.anli.fengcheyangche,
      // textData.anli.saiche,
      // textData.anli.quanjingqiche,
      // textData.anli.qicezhuangpei,
      textData.anli.feitianyundong,
      textData.anli.gedou,
      textData.anli.mengniu,
      textData.anli.feixingsheji,
      textData.anli.taiqiu,
      textData.anli.dahuaxiyoutg,
      textData.anli.kidsyundian,
      textData.anli.dafeixing,
      textData.anli.saiche,
      textData.anli.shengbimaliang,
      textData.anli.liantong,
    ]
  },
  {
    title: '餐饮服务',
    tabItem: [
      textData.anli.haoshixian,
      textData.anli.aojiabaohuyan,
      textData.anli.haerbingpijiu,
      textData.anli.canlangang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.costa,
    ]
  },
  {
    title: '智慧教育',
    tabItem: [
      textData.anli.zhongxing,
      textData.anli.kunyuanyimeng,
      textData.anli.zhongxuediqiuyi,
      textData.anli.arbaikeditu,
      textData.anli.ardiqiuyi,
      textData.anli.wodenongchang,
      textData.anli.shengbimaliang,
      textData.anli.qicezhuangpei,
      textData.anli.xiaofangrenyuan,
      textData.anli.zhongxuedili,
      textData.anli.lajifenlei,
    ]
  },
  {
    title: '智慧文旅',
    tabItem: [
      textData.anli.meilizhongguo,
      textData.anli.hongcunjiyi,
      textData.anli.qiaojiadayuan,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.youlun,
      textData.anli.haiyangguan,
      textData.anli.leiyoushanghai,
      textData.anli.yuanliangxiaochuan,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.bowuguan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.paizhaoshibie,
      textData.anli.dahuaxiyoutg,
      textData.anli.jingqudaka,
      textData.anli.meilizhongguo,
      textData.anli.hongcunjiyi,
      textData.anli.qiaojiadayuan,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.youlun,
      textData.anli.haiyangguan,
    ]
  },
  {
    title: '房产家居',
    tabItem: [
      textData.anli.haixingkongtiao,
      textData.anli.dingshengdichang,
      textData.anli.jiadian
    ]
  },
  {
    title: '公共服务',
    tabItem: [
      textData.anli.dongchengjieneng,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.sitongbanjia,
      textData.anli.wodenongchang,
      textData.anli.miaojiankang,
      textData.anli.xiaofangrenyuan,
      textData.anli.liantong,
      textData.anli.lajifenlei,
    ]
  },
  // {
  //   title: '汽车出行',
  //   tabItem: [
  //     textData.anli.baicaiershouche,
  //     textData.anli.gaoerfu,
  //     textData.anli.fengcheyangche,
  //     textData.anli.saiche,
  //     textData.anli.quanjingqiche,
  //     textData.anli.qicezhuangpei,
  //   ]
  // },
  {
    title: '游戏',
    tabItem: [
      textData.anli.feitianyundong,
      textData.anli.gedou,
      textData.anli.mengniu,
      textData.anli.feixingsheji,
      textData.anli.taiqiu,
      textData.anli.dahuaxiyoutg,
      textData.anli.kidsyundian,
      textData.anli.dafeixing,
      textData.anli.saiche,
      textData.anli.shengbimaliang,
      textData.anli.liantong,
    ]
  },
]
// 大客户定制运营 - 首页
const customersHome = {
  items: [
    {
      tab: "活动方案策划",
      title: "活动内容、策划方案量身定制",
      content: "结合客户运营目标与预算，灵活设计活动方案及功能，更符合您的运营诉求",
      src: 'https://h5.ophyer.cn/official_website/other/saas-tab-img1.png'
    },
    {
      tab: "功能模块定制",
      title: "3C大片级产品功能定制，全方位满足客户需求专业设备",
      content:
        "支持定制开发、本地部署和API对接，致力于提供全渠道、多场景的运营服务",
      src: 'https://h5.ophyer.cn/official_website/other/saas-tab-img2.png'
    },
    {
      tab: "专属运营",
      title: "一对一专属运营指导活动过程全透明可追溯",
      content:
        "签约后即安排一对一的大客户运营经理，全程参与活动运营，依据数据反馈和客户需求，随时调整运营方案",
      src: 'https://h5.ophyer.cn/official_website/other/saas-tab-img3.png'
    },
    {
      tab: "数据复盘",
      title: "活动结束后提供数据分析报告运营效果复盘",
      content:
        "对活动结果即时复盘，对比客户预期，总结活动的内容策划、执行方案及奖品设计，支撑后续运营决策",
      src: 'https://h5.ophyer.cn/official_website/other/saas-tab-img4.png'
    },
  ],
  cardList: [
    {
      title: "产品单独定制化",
      content:
        "AR/VR趣味互动内容定制+技术方案定制、部署，全方位满足客户需求",
      src: require('../../public/img/icons/customerHome/cus2-1.svg'),
    },
    {
      title: "一对一运营指导",
      content:
        "针对品牌方，提供一对一大客户专属运营指导；服务活动全周期",
      src: require('../../public/img/icons/customerHome/cus2-2.svg'),
    },
    {
      title: "资源共享联动运营",
      content:
        "付费大客户共享资源，联动运营，360°赋能品牌影响力，树立行业标杆",
      src: require('../../public/img/icons/customerHome/cus2-3.svg'),
    },
    {
      title: "7*24管家服务",
      content: "100+资深工程师7*24小时在线，全天候保障活动稳定",
      src: require('../../public/img/icons/customerHome/cus2-4.svg'),
    },
  ],
  peitaoList: [
    { title: '电商零售', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img1.png' },
    { title: '3C数码', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img2.png' },
    { title: '房产家居', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img3.png' },
    { title: '汽车', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img4.png' },
    { title: '智慧文旅', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img5.png' },
    { title: '智慧教育', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img6.png' },
    { title: '酒店民宿', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img7.png' },
    { title: '餐饮', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img8.png' },
  ],
}
// AI学科
const AICase = [
  {
    title: '全部分类',
    tabItem: [
      textData.anli.ziranyuyan,
      textData.anli.anfangzhongduan,
      textData.anli.zhinengjishu,
      textData.anli.xianzhihuijiaotong,
      textData.anli.zhuanjiachangtan,
      textData.anli.wanyijiama,
      textData.anli.jingyonganfang,
      textData.anli.qinghua,
      textData.anli.guangdonggaoxiao,
      textData.anli.rengongzhineng,
      textData.anli.jiangsugaoxiao,
      textData.anli.jiqixuexi,
      textData.anli.benke37,
      textData.anli.changjiansuanfa,
      textData.anli.zhihuijinrong,
      textData.anli.wugefadianli,
      textData.anli.yantaigaoxiao,
      textData.anli.renkoulaolinghua,
    ]
  },
  {
    title: 'AI与生活',
    tabItem: [
      textData.anli.anfangzhongduan,
      textData.anli.xianzhihuijiaotong,
      textData.anli.zhuanjiachangtan,
      textData.anli.wanyijiama,
      textData.anli.jingyonganfang,
      textData.anli.zhihuijinrong,
    ]
  },
  {
    title: 'AI挖掘技术',
    tabItem: [
      textData.anli.ziranyuyan,
      textData.anli.zhinengjishu,
      textData.anli.rengongzhineng,
      textData.anli.qinghua,
      textData.anli.jiqixuexi,
      textData.anli.changjiansuanfa,
    ]
  },
  {
    title: 'AI学术',
    tabItem: [
      textData.anli.wugefadianli,
      textData.anli.renkoulaolinghua,
    ]
  },
  {
    title: '高校研究院',
    tabItem: [
      textData.anli.guangdonggaoxiao,
      textData.anli.benke37,
      textData.anli.jiangsugaoxiao,
      textData.anli.yantaigaoxiao,
    ]
  },
]
// h5 AR创意运营
const h5SolutionAR = {
  bannerList: ["https://h5.ophyer.cn/official_website/banner/h5Home-banner01.png", "https://h5.ophyer.cn/official_website/banner/h5Home-banner02.png"],
  module: {
    title: 'AR互动游戏',
    // subtitle:'海量游戏模板+多种运营玩法，助力商家运营转化',
    subtitle: '海量游戏模板+多种互动玩法，助力商家转化',
    listArr: [
      {
        tabTitle: '海量模板一键套用',
        img: 'https://h5.ophyer.cn/official_website/other/h5ARhome1.png',
        // lunTitle: '分场景、分目标\n满足多种需求的运营玩法',
        lunTitle: '分场景、分目标\n满足多种需求的互动玩法',
        // tip: '拉新、引流、促活、促转化，轻松制作即可生成符合自己运营需求的趣味小游戏',
        tip: '拉新、引流、促活、促转化，轻松制作即可生成符合自己需求的趣味小游戏',
        text: []
      },
      {
        tabTitle: '趣味AR识别',
        img: 'https://h5.ophyer.cn/official_website/other/h5ARhome2.png',
        lunTitle: '多种玩法的AR识别方式\n全方位调动用户参与性',
        tip: '图像识别、物体识别、人脸识别、陀螺仪、重力感应，更多趣味玩法持续迭代中…',
        text: []
      },
      {
        tabTitle: '多种奖励玩法',
        img: 'https://h5.ophyer.cn/official_website/other/h5ARhome3_1.jpg',
        // lunTitle: '转盘、抽奖、分层奖励等多种奖励\n玩法，满足更多运营场景',
        lunTitle: '转盘、抽奖、分层奖励等多种奖励\n玩法，满足更多场景',
        tip: 'AR互动游戏结合实物、卡券/卡密、积分等多种奖励方式，游戏好玩又有趣，有效刺激用户自传播',
        text: []
      },
      {
        tabTitle: '分享裂变属性',
        img: 'https://h5.ophyer.cn/official_website/other/h5ARhome4_1.jpg',
        lunTitle: '海报分享、复活游戏分享等多种分享\n触点，提升游戏曝光率',
        tip: 'AR互动游戏结合实物、卡券/卡密、积分等多种奖励方式，游戏好玩又有趣，有效刺激用户自传播',
        text: []
      }
    ]
  },
  module1: [
    {
      title: "模板编辑方式\n轻松生成趣味互动内容",
      icon: "https://h5.ophyer.cn/official_website/other/ARhome2-1-1.png",
      explain: "简单操作即可完成内容制作，减低使用门槛",
    },
    {
      title: "游戏界面灵活配置\n提升品牌影响力",
      icon: "https://h5.ophyer.cn/official_website/other/ARhome2-2.png",
      explain: "界面元素及内容均支持自定义，让用户在参与游戏的同时加深品牌印象",
    }
  ],
}
// h5 VR全景网站
const h5VRpanoramaWebsite = {
  module: {
    title: 'VR全景系统',
    subtitle: '720°沉浸式体验，胜似“身临其境”',
    listArr: [
      {
        id: 1,
        tabTitle: 'VR展厅',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img01-1.png',
        lunTitle: 'VR展厅',
        tip: '沉浸式交互体验，全面展示品牌信息',
        text: [
          '• 720°可交互全景，为您量身打造线上虚拟展厅',
          // '• 热点、虚拟导购等特色功能，适配更多运营场景',
          '• 热点、虚拟导购等特色功能，适配更多场景',
          // '• 热点、嵌入、虚拟人物等特色功能，适配更多运营场景'
          '• 热点、嵌入、虚拟人物等特色功能，适配更多场景'
        ]
      },
      {
        id: 2,
        tabTitle: 'VR带看',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img02-1.png',
        lunTitle: 'VR带看',
        tip: '互动演示，实现VR远程带看',
        text: [
          '• 邀请多个用户共同加入联动，远程演示更方便',
          '• 打破空间和距离，无障碍交流更高效',
          '• 语音、说一说实时互动，交流本该如此简单'
        ]
      },
      {
        id: 3,
        tabTitle: '3D模型',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img03-1.png',
        lunTitle: '3D模型',
        tip: '3D场景模型，产品体验更真实',
        text: [
          '• 支持FBX、GLTF多种模型导入，适用性更强',
          '• 3D展示产品信息，交互更强，距离更近'
        ]
      },
      {
        id: 4,
        tabTitle: '360环物',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-01.png',
        lunTitle: '360环物',
        tip: '贴合电商场景，让产品“动”起来',
        text: [
          '• 简单拍摄即可生成360环物',
          '• 打破传统的静态图片展示，更多细节展示',
          '• 电商、汽车、展馆等多场景适用'
        ]
      },
      /* {
        id:5,
        tabTitle: '虚拟导购',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img05-1.png',
        lunTitle: '虚拟导购',
        tip: '虚拟形象导购+智能语音讲解，更具亲和力',
        text: [
          '• 系统提供多款形象多款动作，适用性更强',
          '• 支持添加欢迎语，强化第一印象',
          '• 动作引导结合语音讲解，代入感更强'
        ]
      }, */
      {
        id: 6,
        tabTitle: '电子沙盘',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img06_1.jpg',
        lunTitle: '电子沙盘',
        tip: '全局总览，想看哪里点哪里',
        text: [
          '• 提升场景辨识度，用于路线规划和导引',
          '• 不同场景可设置不同的沙盘概览图，提升用户体验感',
          '• 可根据漫游点为沙盘添加标记，浏览场景更便捷'
        ]
      }
    ]
  },
  module1: {
    title: 'VR全景拍摄',
    subtitle: '为有VR需求企业提供拍摄、制作、后期一体化服务”',
    listArr: [
      {
        tabTitle: '服务介绍',
        itemList: [
          {
            title: '金牌摄影师专业拍摄',
            src: 'https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite1.png',
            content: '国内知名摄影师提供一对一上门服务，高效率、专业级拍摄，完美还原真实场景'
          },
          {
            title: '3C大片级专业设备',
            src: 'https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite2.png',
            content: '国产、进口专业设备租赁，提供VR全景拍摄、视频拍摄、照片拍摄、3D环物拍摄'
          },
          {
            title: '专业技术团队打造真实全景',
            src: 'https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite3.png',
            content: '行业内10年深耕技术团队一对一定制化开发，致力于打造一比一真实场景'
          },
          {
            // title:'助力企业扩展运营方式',
            title: '助力企业扩展运营方式',
            src: 'https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite4.png',
            // content:'十二年资深品牌运营专家为企业提供定制化VR全景运营策略，助力企业运营方式拓展'
            content: '十二年资深品牌运营专家为企业提供定制化VR全景运营策略，助力企业运营方式拓展'
          }
        ]
      },
      {
        tabTitle: '服务流程',
        itemList: [
          { src: 'https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite5.png' },
        ]
      },
      {
        tabTitle: '解决方案',
        itemList: [
          // { title: '导播间', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-1.png'},
          // { title: '休息区', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-2.png'},
          // { title: '会议室', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-3.png'},
          // { title: '化妆间', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-4.png'},
          // { title: '摄像', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-5.png'},
          // { title: '设计策划', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-6.png'},
          // { title: '演员灯光', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-7.png'},
          // { title: '影视后期', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-8.png'},

          { title: '博物馆', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-9.png' },
          { title: '艺术展图', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-10.png' },
          { title: '商业会展', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-11.png' },
          { title: '学校/企业', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-12.png' },
          { title: '建筑酒店', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-13.png' },
          { title: '旅游景区', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-14.png' },

        ]
      }
    ]
  }
}
// h5 大客户定制
const h5BigCustomer = {
  module: {
    title: '定制服务介绍',
    // subtitle:'服务活动运营全链路，一体化赋能运营目标',
    subtitle: '服务活动全链路，一体化赋能活动目标',
    itemList: [
      {
        title: "活动内容、 策划方案量身定制",
        icon: "https://h5.ophyer.cn/official_website/other/wmyH5BigCustomer1.png",
        explain: "结合客户运营目标与预算，灵活设计活动方案及功能，更符合您的运营诉求",
      },
      {
        title: "产品功能定制 全方位满足客户需求",
        icon: "https://h5.ophyer.cn/official_website/other/wmyH5BigCustomer2.png",
        explain: "支持定制开发、本地部署和API对接，致力于提供全渠道、多场景的运营服务",
      },
      {
        title: "一对一专属运营指导 活动过程全透明可追溯",
        icon: "https://h5.ophyer.cn/official_website/other/wmyH5BigCustomer3.png",
        explain: "签约后即安排一对一的大客户运营经理，全程参与活动运营，依据数据反馈和客户需求，随时调整运营方案",
      },
      {
        // title:"活动结束后提供数据分析 报告，运营效果复盘",
        title: "活动结束后提供数据分析 报告，数据效果复盘",
        icon: "https://h5.ophyer.cn/official_website/other/wmyH5BigCustomer4.png",
        explain: "对活动结果即时复盘，对比客户预期，总结活动的内容策划、执行方案及奖品设计，支撑后续运营决策",
      },
    ]
  },
  module1: {
    title: '为什么选择我们',
    // subtitle:'服务活动运营全链路，一体化赋能运营目标',
    subtitle: '服务活动全链路，一体化赋能活动目标',
    itemList: [
      {
        title: "产品单独定制化",
        src: require('../../public/img/icons/customerHome/cus2-1.svg'),
        // content: "支持定制开发、本地部署和API对接，致力于提供全渠道、多场景的运营服务",
        content: "支持定制开发、本地部署和API对接，致力于提供全渠道、多场景的服务",
      },
      {
        title: "一对一运营指导",
        src: require('../../public/img/icons/customerHome/cus2-2.svg'),
        content: "针对品牌方，提供一对一大客户专属运营指导；服务活动全周期",
      },
      {
        // title:"资源共享联动运营",
        title: "资源共享",
        src: require('../../public/img/icons/customerHome/cus2-3.svg'),
        // content: "付费大客户共享资源，联动运营，360°赋能品牌影响力，树立行业标杆",
        content: "付费大客户共享资源，360°赋能品牌影响力，树立行业标杆",
      },
      {
        title: "7*24管家服务",
        src: require('../../public/img/icons/customerHome/cus2-4.svg'),
        content: "100+资深工程师7*24小时在线，全天候保障活动稳定",
      },
    ]
  },
  module2: {
    title: '应用场景',
    subtitle: '',
    itemList: [
      {
        title: "电商零售",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img1.png"
      },
      {
        title: "3C数码",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img2.png"
      },
      {
        title: "房产家居",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img3.png"
      },
      {
        title: "汽车",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img4.png"
      },
      {
        title: "智慧文旅",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img5.png"
      },
      {
        title: "智慧教育",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img6.png"
      },
      {
        title: "酒店民宿",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img7.png"
      },
      {
        title: "餐饮",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img8.png"
      },
    ]
  },
}

// h5 推广
const extensionH5 = {
  module: {
    title: '定制服务介绍',
    subtitle: '服务活动运营全链路，一体化赋能运营目标',
    itemList: [
      {
        title: "海量模版一键套用轻松生成趣味互动内容",
        icon: "https://h5.ophyer.cn/official_website/other/tuiguang01.png",
        explain: "• AR趣味互动游戏<br/> • 多种AR识别<br/> • VR全景店铺 <br/>• VR带看",
      },
      {
        title: "产品功能定制全方位满足客户需求",
        icon: "https://h5.ophyer.cn/official_website/other/tuiguang02.png",
        explain: "• 转盘抽奖 <br/>• 集卡 <br/>• 红包<br/> • 砸金蛋等",
      },
      {
        title: "实时监控传播效果支持运营决策",
        icon: "https://h5.ophyer.cn/official_website/other/tuiguang03.png",
        explain: "• PU、UV、IP数据 <br/>• 中奖、奖品发放、领取数据<br/>• 多维度数据对比",
      },
      {
        title: "线上线强势引流助力运营转化",
        icon: "https://h5.ophyer.cn/official_website/other/tuiguang04.png",
        explain: "• 线上/线下店铺引流<br/>• 公众号引流<br/>• 微信群引流",
      },
    ]
  },
  module1: {
    title: '为什么选择我们',
    subtitle: '服务活动运营全链路，一体化赋能运营目标',
    itemList: [
      {
        title: "产品单独定制化",
        src: require('../../public/img/icons/customerHome/cus2-1.svg'),
        content: "支持定制开发、本地部署和API对接，致力于提供全渠道、多场景的运营服务",
      },
      {
        title: "一对一运营指导",
        src: require('../../public/img/icons/customerHome/cus2-2.svg'),
        content: "针对品牌方，提供一对一大客户专属运营指导；服务活动全周期",
      },
      {
        title: "资源共享联动运营",
        src: require('../../public/img/icons/customerHome/cus2-3.svg'),
        content: "付费大客户共享资源，联动运营，360°赋能品牌影响力，树立行业标杆",
      },
      {
        title: "7*24管家服务",
        src: require('../../public/img/icons/customerHome/cus2-4.svg'),
        content: "100+资深工程师7*24小时在线，全天候保障活动稳定",
      },
    ]
  },
  module2: {
    title: '应用场景',
    subtitle: '',
    itemList: [
      {
        title: "电商零售",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img1.png"
      },
      {
        title: "3C数码",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img2.png"
      },
      {
        title: "房产家居",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img3.png"
      },
      {
        title: "汽车",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img4.png"
      },
      {
        title: "智慧文旅",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img5.png"
      },
      {
        title: "智慧教育",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img6.png"
      },
      {
        title: "酒店民宿",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img7.png"
      },
      {
        title: "餐饮",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img8.png"
      },
    ]
  },
}

// 职业培训
const lavOccupation = {
  module: {
    title: '功能',
    // subtitle:'aPaaS',
    itemList: [
      {
        title: '虚拟仿真实验室',
        src: 'https://h5.ophyer.cn/official_website/other/LavOccupationOther_01.png',
        content: '海量内容配合高性能硬件，带来沉浸式教育体验'
      },
      {
        title: '虚拟仿真试验项目',
        src: 'https://h5.ophyer.cn/official_website/other/LavOccupationOther_02.png',
        content: '为用户提供仿真实验项目开发和管理平台'
      },
      {
        title: '专业建设',
        src: 'https://h5.ophyer.cn/official_website/other/LavOccupationOther_03.png',
        content: '帮助高校推进专业学科的建设'
      }
    ]
  },
  module1: {
    title: '方案优势',
    // subtitle:'APAAS ENGINE',
    itemList: [
      {
        title: '完成生态',
        src: 'https://h5.ophyer.cn/official_website/other/LavOccupatioCase_01.png',
        content: '为用户提供成熟的平台+内容+终端+应用完整生态'
      },
      {
        title: '突破传统屏障',
        src: 'https://h5.ophyer.cn/official_website/other/LavOccupatioCase_02.png',
        content: '无需准备物料及场地，虚拟仿真实验室提供一切实验所需资源'
      },
      {
        title: '可重复操作',
        src: 'https://h5.ophyer.cn/official_website/other/LavOccupatioCase_03.png',
        content: '快速重新开始实验，无需再次准备'
      }
    ]
  },
  module2: {
    title: '综合产品-全息教室',
    // subtitle:'APAAS ENGINE',
    src: 'https://h5.ophyer.cn/official_website/other/LavOccupatioOther_01.png',
    imgTitle: '让教室变得交互立体',
    imgText: '全息教室是针对传统教室提出的交互式，立体式的VR升级\n教室解决方案，结合行业先进的XR软硬件技术，丰富的课\n程资源，以及科技感的一体化设计，形成了完善的XR智慧\n虚拟 仿真实验室解决方案'

  },
  module3: {
    title: '展示终端',
    // subtitle:'3D CONTENT PRODUCTIO',
    itemList: [
      { title: '手机电脑端', src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_01.png', content: '可以在线上转发传播' },
      { title: '飞天感知交互台', src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_02.png', content: '智能触控技术为核心的智能交互桌面式显示终端' },
      { title: '飞天立体交互墙', src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_03.png', content: '在小间距LED的基础上增加交互装置 打造了替代投影教学方案的全新沉浸式教学系统' },
      { title: '飞天沉浸互动屋', src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_04.png', content: 'V-CAVE投影系统是由3个面以上硬质背投影墙组成是一套专门为虚拟仿真实验而打造的沉浸系统' },
      { title: '飞天立体交互桌', src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_05.png', content: '基于全息台用户可以在自由的空间内对模型进行多角度观察，并进行分析、修改、操控等交互' },
      { title: '交互驾驶模拟仓', src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_06.png', content: '半实物虚拟仿真培训平台 是一套结合了算法、数据、工艺操作的大型综合模拟操作培训系统' },

    ]
  }
}
// 元宇宙解决方案
const lavMetaverse = {
  module: {
    title: '产品功能',
    itemList: [
      {
        title: '虚拟空间定制',
        src: 'https://h5.ophyer.cn/official_website/other/LavMetaverseOther_04.png',
        content: '每个虚拟空间都是独家定制的数字都市，商家或合作伙伴能发挥想象，打造独家专属的品牌世界'
      },
      {
        title: '全真人机互动',
        src: 'https://h5.ophyer.cn/official_website/other/LavMetaverseOther_02.png',
        content: '每个用户都可以定制你专属的角色形象，并通过虚拟形象，与其他人进行即时的语音、互动交流'
      },
      {
        title: '多平台支持',
        src: 'https://h5.ophyer.cn/official_website/other/LavMetaverseOther_01.png',
        content: '生产的内容支持上传到飞天元宇宙，百度希壤，腾讯TMeland,网易伏羲 瑶台等元宇宙平台上去'
      },
      {
        title: '数据运营',
        src: 'https://h5.ophyer.cn/official_website/other/LavMetaverseOther_03.png',
        content: '帮助客户获取新用户运营老用户，让客户的元宇宙空间充满活力'
      }
    ]
  },
  module1: {
    title: '技术优势',
    itemList: [
      {
        title: 'AI智能',
        src: 'https://h5.ophyer.cn/official_website/other/lavMetaverseOther_05.png',
        content: 'AI技术赋能，智能虚拟角色逼真的交互体验'
      },
      {
        title: '极限观感',
        src: 'https://h5.ophyer.cn/official_website/other/lavMetaverseOther_06.png',
        content: '全真3D场景'
      },
      {
        title: '多端兼容',
        src: 'https://h5.ophyer.cn/official_website/other/lavMetaverseOther_07.png',
        content: '支持VR、PC、iOS、Android全面打通'
      },
      {
        title: '永久复用',
        src: 'https://h5.ophyer.cn/official_website/other/lavMetaverseOther_08.png',
        content: '一次搭建，长期使用，更新便捷'
      }
    ]
  },
  module2: {
    title: '精彩案例',
    subtitle1: '圆明园全真VR体验馆',
    src: 'https://h5.ophyer.cn/official_website/other/lavMetaverseOther_09.png',
    imgTitle: '引领时代的全真沉浸VR体验馆',
    imgText: ['圆明园主题——历史、故事、风景、建筑', '支持VR头显，带来更沉浸体验', '帮助客户部署到百度希壤平台中', '团客绑定，活动运营，内容持续创作，帮助客户吸引流量']

  }
}

// 媒体运营
const lavMedia = {
  module: {
    title: '让品牌更有吸引力',
    subtitle: '营销服务平台，共生商业的实践者，致力于践行产品和技术的平等赋能，建立共赢生态，让品牌、商家、用户、创作者发挥更大的运营价值。',
    itemList: [
      {
        img: 'https://h5.ophyer.cn/official_website/other/LavMediaOther_01.png',
        width: '30px',
        sub: '元宇宙3D场景内 - 互动运营情景',
      },
      {
        img: 'https://h5.ophyer.cn/official_website/other/LavMediaOther_02.png',
        width: '30px',
        sub: '元宇宙3D场景 - 广告植入',
      },
      {
        img: 'https://h5.ophyer.cn/official_website/other/LavMediaOther_03.png',
        width: '30px',
        sub: 'VR全景·实景里的广告植入'
      },
      {
        img: 'https://h5.ophyer.cn/official_website/other/LavMediaOther_04.png',
        width: '32px',
        sub: '互动游戏里的广告 '
      }
    ],
  },
  module1: {
    title: '流量高效触达  内容多元渗透',
    subtitle: '多重信息传播路径，精准覆盖浏览渠道，帮助品牌抢夺用户注意',
    itemList: [
      {
        id: 1,
        tabTitle: '标准广告位',
        img: require("@/assets/images/cc.jpg"),
        lunTitle: '标准广告位 变现量极大\n结合DMP系统智能投放',
        // lunTitle1: '打造个性化AR运营',
        // lunTitle1: '打造个性化AR活动',
        tip: '适配流量主任意场景，轻量对接快速上线，深度对接长线运营',
        btns: [
          {
            id: 3,
            button: '开屏',
            src1: require('../../public/img/icons/EfficiencyCloud/eff04small.png'),
            imgUrl: 'https://h5.ophyer.cn/official_website/other/lavMedia_1_1.png'
          },
          {
            id: 2,
            button: '插屏',
            src1: require('../../public/img/icons/EfficiencyCloud/eff02small.png'),
            imgUrl: 'https://h5.ophyer.cn/official_website/other/lavMedia_1_2.png'
          },
          {
            id: 1,
            button: 'Banner',
            src1: require('../../public/img/icons/EfficiencyCloud/eff01small.png'),
            imgUrl: 'https://h5.ophyer.cn/official_website/other/lavMedia_1_3.png'
          },
          {
            id: 4,
            button: '信息流',
            src1: require('../../public/img/icons/EfficiencyCloud/eff05small.png'),
            imgUrl: "https://h5.ophyer.cn/official_website/other/lavMedia_1_4.png"
          }
        ]
      },
      {
        id: 2,
        tabTitle: '元宇宙·品牌广告',
        img: require("@/assets/images/cc.jpg"),
        lunTitle: '在元宇宙上投放广告\n提升品牌信息影响力',
        tip: '全新视觉体验引导用户产生更强烈的消费认同，助力品牌运营升级，强效展示吸引点击。',
        btns: [
          {
            id: 3,
            button: '互动情景',
            src1: require('../../public/img/icons/EfficiencyCloud/eff04small.png'),
            imgUrl: 'https://h5.ophyer.cn/official_website/other/lavMedia_2_1.png'
          },
          {
            id: 4,
            button: '品牌嵌入',
            src1: require('../../public/img/icons/EfficiencyCloud/eff05small.png'),
            imgUrl: 'https://h5.ophyer.cn/official_website/other/lavMedia_2_2.png'
          }
        ]
      },
      {
        id: 3,
        tabTitle: 'VR全景·广告位',
        img: require("@/assets/images/cc.jpg"),
        lunTitle: '支持多种类型的广告载具完美契合全景内容',
        tip: '透明载具、X展架、电视机、大屏等多种广告载具，与全景内容相融合，颠覆传统广告展现形式',
        btns: [
          {
            id: 3,
            button: '开屏广告',
            src1: require('../../public/img/icons/EfficiencyCloud/eff04small.png'),
            imgUrl: 'https://h5.ophyer.cn/official_website/other/lavMedia_3_1.png'
          },
          {
            id: 4,
            button: '嵌入式',
            src1: require('../../public/img/icons/EfficiencyCloud/eff05small.png'),
            imgUrl: 'https://h5.ophyer.cn/official_website/other/lavMedia_3_2.png'
          }
        ]
      },
      {
        id: 4,
        tabTitle: '互动游戏·广告位',
        img: require("@/assets/images/cc.jpg"),
        lunTitle: '趣味游戏+广告\n打造个性化互动内容',
        tip: '将广告内容与AR互动小游戏完美结合，扩大品牌影响力的同时提升广告效果',
        btns: [
          {
            id: 1,
            button: '游戏开始前',
            src1: require('../../public/img/icons/EfficiencyCloud/eff01small.png'),
            imgUrl: 'https://h5.ophyer.cn/official_website/other/lavMedia_4_1.png'
          },
          {
            id: 2,
            button: '游戏中',
            src1: require('../../public/img/icons/EfficiencyCloud/eff02small.png'),
            imgUrl: 'https://h5.ophyer.cn/official_website/other/lavMedia_4_2.png'
          }
        ]
      },
    ],
  },
  module2: {
    title: '媒体变现',
    // subtitle:'APAAS ENGINE',
    itemList: [
      {
        title: '沉浸式互动体验',
        src: 'https://h5.ophyer.cn/official_website/other/LavMedia_01.png',
        contentTitle: '内容场景深度定制',
        content: '结合用户行为路径\n深度定制内容场景\n激发用户参与感'
      },
      {
        title: '3000+优质媒体',
        src: 'https://h5.ophyer.cn/official_website/other/LavOccupationOther_02.png',
        contentTitle: '海量广告资源',
        content: '囊括海量移动端app，元宇宙及多\n品牌VR硬件，精准投放，投其所好'
      },
      {
        title: '多样化流量场景',
        src: 'https://h5.ophyer.cn/official_website/other/LavOccupationOther_03.png',
        contentTitle: '支持所有媒体类型',
        content: 'APP、WAP、小程序、公众号、\n多媒体类型实现商业变现'
      },
      {
        title: '专家级运营团队',
        src: 'https://h5.ophyer.cn/official_website/other/LavOccupationOther_04.png',
        contentTitle: '1对1专业团队支持',
        content: '搭配高级变现优化师和经验\n丰富的产品团队支持'
      },
    ]

  },
  module3: {
    title: '精品案例',
    // subtitle:'APAAS ENGINE',
    subtitle1: '圆明园全真VR体验馆',
    list: [
      {
        id: 1,
        img: "https://h5.ophyer.cn/official_website/case/home-zx-img-01.png",
        title: '飞天云动X三星',
        tip: '打造线上沉浸式购物体验，开辟AR/VR互动新视界',
        text: `6月11日，飞天云动为三星打造的京东VR云店“三星薄视空间站”正式起航飞天！飞天云动团队在本次项目中打破常规，
        突破传统云店创作的局限性；同时完美结合科技类产品特点，将创意投向广阔的宇宙空间，
        为消费者打造更加沉浸的VR购物体验。这次大胆的创新具有里程碑式的意义，也奠定了飞天云动在VR云店领域领航员的地位。`,
        content: [
          {
            type: 'text',
            value: `6月11日，飞天云动为三星打造的京东VR云店“三星薄视空间站”正式起航飞天！飞天云动团队在本次项目中打破常规，
            突破传统云店创作的局限性；同时完美结合科技类产品特点，将创意投向广阔的宇宙空间，为消费者打造更加沉浸的VR购物体验。
            这次大胆的创新具有里程碑式的意义，也奠定了飞天云动在VR云店领域领航员的地位。`
          },
          {
            type: 'video',
            style: 'width: 480px;',
            value: "https://h5.ophyer.cn/official_website/case/home-video-01.mp4",
          },
          {
            type: 'text',
            value: `如何打造电视这类科技产品的VR云店？当消费者浏览时间有限时，场景会是最优的表达方式。然而传统家居空间过于平淡，
            商超场景缺少科技感，只有宇宙空间站能够充分表达科技与创新的概念。于是三星电视与宇宙空间站创意结合，“三星薄视空间站”正式诞生。`
          },
          {
            type: 'text',
            value: `根据空间站与产品的特性，策划团队将场景规划为三个分区，分别是“薄视空间站主站”“QX2游戏空间”“QX1临场空间”，
            各分区场景与产品特性深度结合。通过3D建模、C4D场景渲染等技术，打造身临其境的游戏化场景，展示QX2强大的游戏性能；以强烈的科技概念，凸显QX1的完美品质。`
          },
          {
            type: 'img',
            style: 'width: 480px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-01.webp",
          },
          {
            type: 'text',
            value: `【薄视空间站主战】`
          },
          {
            type: 'img',
            style: 'width: 480px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-02.gif",
          },
          {
            type: 'text',
            value: `【QX2游戏空间】`
          },
          {
            type: 'text',
            value: `结合产品强大的游戏性能，整体空间设计以游戏化为核心概念。强烈的游戏氛围、恰到好处的光效以及正下方的游戏手柄，瞬间将用户带入游戏次元。`
          },
          {
            type: 'img',
            style: 'width: 480px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-03.gif",
          },
          {
            type: 'text',
            value: `想了解电视有多薄吗？3D建模让电视动起来！试着转一转，360全方位感受电视的厚度。想知道它放在家中的效果吗？轻轻一点，即可呈现优雅的壁挂效果。`
          },
          {
            type: 'img',
            style: 'width: 480px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-04.gif",
          },
          {
            type: 'img',
            style: 'width: 480px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-05.gif",
          },
          {
            type: 'text',
            value: `在强大的配置面前，虚拟与现实将实现无缝对接。在QX2强大的性能加成下，3D游戏人物冲出屏幕，融入真实环境，增强与用户的交互。`
          },
          {
            type: 'img',
            style: 'width: 480px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-06.gif",
          },
          {
            type: 'text',
            value: `【QX1临场空间】`
          },
          {
            type: 'text',
            value: `镜面效果+科技蓝色调，在营造空间站调性的同时，更加突出产品的科技属性。卖点与场景高度契合，使整体毫无违和感。
            相较于传统的客厅模式，空间站更直观的呈现了产品优势。这一创新改变，加强了用户沉浸式体验，全面提升转化率。`
          },
          {
            type: 'img',
            style: 'width: 480px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-07.gif",
          },
          {
            type: 'text',
            value: `【AR摆摆看，实现所见即所得】`
          },
          {
            type: 'text',
            value: `VR云店，全面提升用户购买欲望，增加转化；结合AR摆摆看技术，可以完美解决用户购买前最大的痛点，消除用户疑虑。`
          },
          {
            type: 'text',
            value: `在网上挑选居家大件商品时，通常很难确定放置在家中的效果，收到货后，往往会与想象存在很大偏差。飞天云动技术团队，
            根据三星电视真实数据建模，打造高质量1:1实物模型，材质逼真，放大后细节清晰可见。借助AR摆摆看黑科技，三星电视可以直接“搬进”家中，
            预览1:1实物摆放效果，实现所见即所得。同时免去到手商品与期望不符而产生的困扰，大幅提升购买效率，省心更省事。
            `
          },
        ]
      },
      {
        id: 2,
        img: "https://h5.ophyer.cn/official_website/case/home-zx-img-02.png",
        title: '飞天云动x京东x泡泡玛特',
        tip: '618全民狂欢季，让我们“为热爱奔跑”！',
        text: `随着京东618活动的正式开启，由京东和泡泡玛特创意联名、飞天云动策划开发的跑酷类竞技运动——“为热爱奔跑”正式上线啦！
        飞天云动团队在此次“为热爱奔跑”项目中不断开拓创新，通过敏锐的行业洞察、游戏化的创新思维，先进的研发技术，为用户打造出了集趣味性、互动性、挑战性于一体的优秀竞技作品，助力京东618购物节再攀高峰！`,
        content: [
          {
            type: 'text',
            value: `随着京东618活动的正式开启，由京东和泡泡玛特创意联名、飞天云动策划开发的跑酷类竞技运动——“为热爱奔跑”正式上线啦！
            飞天云动团队在此次“为热爱奔跑”项目中不断开拓创新，通过敏锐的行业洞察、游戏化的创新思维，先进的研发技术，为用户打造出了集趣味性、
            互动性、挑战性于一体的优秀竞技作品，助力京东618购物节再攀高峰！`
          },
          {
            type: 'video',
            style: 'width: 700px;',
            value: "https://h5.ophyer.cn/official_website/case/home-video-03.mp4",
          },
          {
            type: 'text',
            value: `你试过在科幻空间玩转跑酷运动吗？“为热爱奔跑”跑酷场景就搭建在科幻空间中。跑酷开始前，可以选择泡泡玛特角色或京东小动物角色参赛
            （小动物角色需要搜集道具解锁）。在跑酷过程中，泡泡玛特品牌元素不时出现在赛道两侧，品牌与场景结合的恰到好处，和谐而不突兀。`
          },
          {
            type: 'img',
            style: 'width: 700px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-08.png",
          },
          {
            type: 'img',
            style: 'width: 700px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-09.png",
          },
          {
            type: 'text',
            value: `每场比赛初始时间为60s，速度档位有3档，角色速度根据当局跑酷时长而变化。遇到障碍时，可通过上划屏幕实现跳跃躲避，
            或者通过左右划屏、左右倾斜手机变换赛道，实现障碍躲避（手机开通陀螺仪功能后，可以使用倾斜手机变换赛道功能）。`
          },
          {
            type: 'text',
            value: `在跑酷过程中，会出现皇冠与音符等得分道具，用于提升排行、解锁更多角色；遇到带有增益BUFF的盲盒，
            可以获得增加比赛时长、获得无敌状态等增益效果；若碰撞到路障，则会减少角色血量，要小心避开哦～`
          },
          {
            type: 'img',
            style: 'width: 700px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-10.jpg"
          },
          {
            type: 'text',
            value: `每日前5场比赛结束后结算“收益”，可获得京东金币奖励（单用户每日金币上限5000）。
            点击排行榜，可以查看当日用户排名（次日清零）；想在排行榜上留下足迹吗？赶快来参加挑战吧～`
          },
          {
            type: 'img',
            style: 'width: 700px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-11.jpg",
          },
          {
            type: 'text',
            value: `“为热爱奔跑”作为618活动中的跨界合作项目，充分融合了京东与泡泡玛特双方品牌的资源，
            让品牌双方的更多用户参与其中，在运营与互动层面实现了1+1>2的共赢新局面。同时，作为京东的战略合作伙伴，
            飞天云动将和京东在AR/VR及更多领域展开多元化的合作，助力提升品牌与用户的深度沟通，提供更好的价值传递。`
          },
        ]
      },
      {
        id: 3,
        img: "https://h5.ophyer.cn/official_website/case/home-zx-img-03.png",
        title: '飞天云动x百度国际网络安全',
        tip: '飞天云动科幻巨制，经典赛车竞技助阵百度网络安全2020赛季收官之战！',
        text: `2021年3月20日，备受关注的BCTF（全称：百度国际网络安全对抗赛）2020赛季圆满收官。
        作为DEF CON CHINA最具竞争性的环节，BCTF的赛季总决赛向来是安全圈和极客们最为关注的焦点。
        苹果联合创始人沃兹尼亚克、李开复、冯唐、吉娜、乃万、吴青峰、杨凯等各行业翘楚，为赛事亲自助阵，共同开启未来世界！`,
        content: [
          {
            type: 'text',
            style: 'width: 700px;',
            value: `2021年3月20日，备受关注的BCTF（全称：百度国际网络安全对抗赛）2020赛季圆满收官。
            作为DEF CON CHINA最具竞争性的环节，BCTF的赛季总决赛向来是安全圈和极客们最为关注的焦点。
            苹果联合创始人沃兹尼亚克、李开复、冯唐、吉娜、乃万、吴青峰、杨凯等各行业翘楚，为赛事亲自助阵，共同开启未来世界！`
          },
          {
            type: 'img',
            style: 'width: 700px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-12.webp",
          },
          {
            type: 'text',
            value: `DEF CON（极客大会）是全球安全领域的顶级会议，迄今已有28年历史，被称作安全界的“奥斯卡”。
            本次DEF CON CHINA Party赛事以线上VR的方式进行，这也是DEF CON历史上首次“全VR”大会！为此，
            主办方构建了名为The DEF CONstruct的虚拟空间和赛博世界，精彩的赛事就在此上演。`
          },
          {
            type: 'video',
            style: 'width: 700px;',
            value: "https://h5.ophyer.cn/official_website/case/home-video-03-01.mp4",
          },
          {
            type: 'text',
            value: `飞天云动倾力加盟赛事，自主研发的高性能赛车竞技运动，首发亮相赛事现场，为赛事助阵。
            场景采用PBR的材质管道设计，基于Real Time（实时操作）系统打造，逼真的赛车体验，把赛事氛围再次推向顶峰。`
          },
          {
            type: 'img',
            style: 'width: 700px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-13.png",
          },
          {
            type: 'text',
            value: `高度可控的汽车物理系统、道路管理系统、天气控制系统以及智慧的AI系统，四维度的营造刺激的竞速氛围，带来身临其境的赛车体验；`
          },
          {
            type: 'img',
            style: 'width: 700px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-14.png",
          },
          {
            type: 'text',
            value: `动感艺术性的超车表现设计，配合临场感超真实的摄像机捕捉系统，让参与者不放过每一个精彩镜头，赛车道两侧街景植入百度LOGO以及DEFCON广告牌，增强现场融入感。`
          },
          {
            type: 'img',
            style: 'width: 700px;',
            value: "https://h5.ophyer.cn/official_website/case/home-img-15.png",
          },
          {
            type: 'text',
            value: `此款赛车竞技运动在百度网络安全大会上的首发亮相，为参赛者呈现了一场完美的视觉盛宴，助阵百度网络安全2020赛季圆满收官！`
          },
        ]
      }
    ]
  }
}
export default {
  WanqingCloud,
  ShootingService,
  IntelligentCulturalTourism,
  WisdomEducation,
  InvestmentPromotion,
  ARHome,
  ARcase,
  ARSolution,
  customersSolution,
  customersCase,
  customersHome,
  AICase,
  h5SolutionAR,
  h5VRpanoramaWebsite,
  h5BigCustomer,
  extensionH5,
  lavOccupation,
  lavMetaverse,
  lavMedia
}